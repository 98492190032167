'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('heroClass', function(isMainHero) {
    if (isMainHero) {
        return 'hero1';
    }
    return 'hero2';
});
