'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('ifStock', function(conditional, options) {
    if (conditional > 0) {
        return options.fn(this);
    }
    return options.inverse(this);
});
