'use strict';

/**
 * This module makes call to DanDomain API (via cinemagic API)
 * to fetch list of products in frontpage category (9999)
 * if call is success, it takes three random products from list
 * and renders them as hero elements on frontpage
 */

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

var API = require('../../../../configs/services').cinemagicAPI;

// Loading Handlebars
var Handlebars = require('hbsfy/runtime');
var heroTemplate = require('templates/hero');
require('../handlebars/helpers/hero-class');
require('../handlebars/helpers/is-stock');
require('../handlebars/helpers/format-stock');

var productTile = require('../../components/_product-tile');

function loadHero(callback) {
    $.ajax({
        type: 'GET',
        url: API + '/get-products/9999',
        timeout: 4000,
        success: function(data) {
            if (data.length >= 3) {
                var heros = selectHeros(data);
                setCorrectPrice(heros);
                var content = heroTemplate(heros[0]) + heroTemplate(heros[1]) + heroTemplate(heros[2]);
                $('.Frontpage_Custom_DIV').prepend(content);
                callback();
            }
        }
    });
}

function setCorrectPrice(products) {
    for (var i = 0; i < products.length; i++) {
        var product = products[i];
        if (product && product.specialOfferPrice) {
            product.priceIncVAT = product.specialOfferPrice;
        }
    }
}


function selectHeros(json) {
    var inStock = [];
    var outOfStock = [];
    var result = [];
    for (var i = 0; i < json.length; i++) {
        var product = json[i];
        if (product.stockCount > 0) {
            inStock.push(product);
        } else {
            outOfStock.push(product);
        }
    }
    if (inStock.length >= 3) {
        var randomNumbers = getRandomArray(3, inStock.length);
        for (var j = 0; j < randomNumbers.length; j++) {
            var random = randomNumbers[j];
            result.push(inStock[random]);
        }
    } else {
        result = inStock;
        for (var r = 0; result.length < 3; r++) {
            result.push(outOfStock[r]);
        }
    }

    if (result.length) {
        result[0].isMainHero = true;
    }
    return result;
}

function getRandomArray(length, max) {
    var result = [];
    var first = getRandomNumber(max, result);
    result.push(first);
    var second = getRandomNumber(max, result);
    result.push(second);
    var third = getRandomNumber(max, result);
    result.push(third);
    return result;
}

function getRandomNumber(max, excludeArray) {
    var result = Math.floor(Math.random() * max);
    for (var i = 0; i < excludeArray.length; i++) {
        var exclude = excludeArray[i];
        if (exclude === result) {
            return getRandomNumber(max, excludeArray);
        }
    }
    return result;
}

function heroCallback() {
    $('.hero2:last').after($('.value-props'));
    $('.hero1').after($('.js-promoBanner'));
    productTile.update();
}

function init() {
    loadHero(heroCallback);
}

// Initializes landing hero
module.exports = init;
