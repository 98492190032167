require=(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({26:[function(require,module,exports){
'use strict';

var R = require(29);

var hero = require(33);
var productTile = require(21);
var headerMessage = require(15);

R(R.EVENTS.LOAD, R.SECTIONS.FRONTPAGE, function() {
    productTile.update();
    if (window.CineMagicConfig && window.CineMagicConfig.hero) {
        hero();
    }
    headerMessage.showMessage();
    R.fire(R.EVENTS.POST_LOAD);
});

},{"15":15,"21":21,"29":29,"33":33}],33:[function(require,module,exports){
(function (global){
'use strict';

/**
 * This module makes call to DanDomain API (via cinemagic API)
 * to fetch list of products in frontpage category (9999)
 * if call is success, it takes three random products from list
 * and renders them as hero elements on frontpage
 */

var $ = (typeof window !== "undefined" ? window['$'] : typeof global !== "undefined" ? global['$'] : null);

var API = require(7).cinemagicAPI;

// Loading Handlebars
var Handlebars = require(116);
var heroTemplate = require(92);
require(45);
require(47);
require(43);

var productTile = require(21);

function loadHero(callback) {
    $.ajax({
        type: 'GET',
        url: API + '/get-products/9999',
        timeout: 4000,
        success: function(data) {
            if (data.length >= 3) {
                var heros = selectHeros(data);
                setCorrectPrice(heros);
                var content = heroTemplate(heros[0]) + heroTemplate(heros[1]) + heroTemplate(heros[2]);
                $('.Frontpage_Custom_DIV').prepend(content);
                callback();
            }
        }
    });
}

function setCorrectPrice(products) {
    for (var i = 0; i < products.length; i++) {
        var product = products[i];
        if (product && product.specialOfferPrice) {
            product.priceIncVAT = product.specialOfferPrice;
        }
    }
}


function selectHeros(json) {
    var inStock = [];
    var outOfStock = [];
    var result = [];
    for (var i = 0; i < json.length; i++) {
        var product = json[i];
        if (product.stockCount > 0) {
            inStock.push(product);
        } else {
            outOfStock.push(product);
        }
    }
    if (inStock.length >= 3) {
        var randomNumbers = getRandomArray(3, inStock.length);
        for (var j = 0; j < randomNumbers.length; j++) {
            var random = randomNumbers[j];
            result.push(inStock[random]);
        }
    } else {
        result = inStock;
        for (var r = 0; result.length < 3; r++) {
            result.push(outOfStock[r]);
        }
    }

    if (result.length) {
        result[0].isMainHero = true;
    }
    return result;
}

function getRandomArray(length, max) {
    var result = [];
    var first = getRandomNumber(max, result);
    result.push(first);
    var second = getRandomNumber(max, result);
    result.push(second);
    var third = getRandomNumber(max, result);
    result.push(third);
    return result;
}

function getRandomNumber(max, excludeArray) {
    var result = Math.floor(Math.random() * max);
    for (var i = 0; i < excludeArray.length; i++) {
        var exclude = excludeArray[i];
        if (exclude === result) {
            return getRandomNumber(max, excludeArray);
        }
    }
    return result;
}

function heroCallback() {
    $('.hero2:last').after($('.value-props'));
    $('.hero1').after($('.js-promoBanner'));
    productTile.update();
}

function init() {
    loadHero(heroCallback);
}

// Initializes landing hero
module.exports = init;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{"116":116,"21":21,"43":43,"45":45,"47":47,"7":7,"92":92}],92:[function(require,module,exports){
// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        "
    + escapeExpression(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            <div class=\"StockCount\">\n                                <div class=\"StockCountText\">\n                                    <span class=\"StockVal\">"
    + escapeExpression(((helpers.formatStock || (depth0 && depth0.formatStock) || helperMissing).call(depth0, (depth0 != null ? depth0.stockCount : depth0), {"name":"formatStock","hash":{},"data":data})))
    + "</span> p&aring; lager\n                                </div>\n                                <div class=\"DeliveryTime\">\n                                    <div class=\"DeliveryTimeText js-deliveryTime\">\n                                        <span class=\"Stockmessage_Productlist\">1-2 dage</span> <span class=\"greyText\">leveringstid</span>\n                                    </div>\n                                </div>\n                            </div>\n";
},"5":function(depth0,helpers,partials,data) {
  return "                            <div class=\"StockCount\">\n                                <div class=\"StockCountText\">\n                                    P&aring; fjernlager\n                                </div>\n                                <div class=\"DeliveryTime\">\n                                    <div class=\"DeliveryTimeText js-deliveryTime\">\n                                        <span class=\"Stockmessage_Productlist\">3 dage</span> <span class=\"greyText\">leveringstid</span>\n                                    </div>\n                                </div>\n                            </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"hero "
    + escapeExpression(((helpers.heroClass || (depth0 && depth0.heroClass) || helperMissing).call(depth0, (depth0 != null ? depth0.isMainHero : depth0), {"name":"heroClass","hash":{},"data":data})))
    + "\">\n    <form style=\"margin:0px;\" method=\"post\" action=\"/shop/basket.asp\" name=\"myform"
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "\" onsubmit=\"return BuyProduct(this,'1','0','False');\">\n        <input type=\"hidden\" name=\"VerticalScrollPos\" value=\"\"><input type=\"hidden\" name=\"BuyReturnUrl\" value=\"/shop/frontpage.html\"><input type=\"hidden\" name=\"Add\" value=\"1\"><input type=\"hidden\" name=\"ProductID\" value=\""
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"small-6 medium-3 large-3 column product\">\n            <div class=\"Product List row\">\n                <div class=\"small-12 column Image\">\n                    <div>\n                        <a class=\"Product_ImageLink_FrontPage Product_ImageLink_Frontpage\" href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\"><img border=\"0\" src=\""
    + escapeExpression(((helper = (helper = helpers.pictureLink || (depth0 != null ? depth0.pictureLink : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"pictureLink","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" id=\"321596\"></a>\n                        <div class=\"New\"></div>\n                        <div class=\"Offer\"></div>\n                        <div class=\"Overlay\"></div>\n                    </div>\n                </div>\n                <div class=\"small-12 column PriceBox\">\n                    <div class=\"row\">\n                        <div class=\"column small-5 Price\">\n                            <span class=\"js-productPrice hide\">\n                                "
    + escapeExpression(((helper = (helper = helpers.priceIncVAT || (depth0 != null ? depth0.priceIncVAT : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"priceIncVAT","hash":{},"data":data}) : helper)))
    + ",-\n                            </span>\n                            <span class=\"js-specialOfferPrice hide\"></span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"ProdInfo small-12 column\">\n                    <div class=\"ListProdInfo\">\n                        <div class=\"row\">\n                            <div class=\"small-12 column ProdInfoText\">\n                                <div class=\"ProductNumber hide\">\n                                    "
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "\n                                </div>\n                                <h4>\n                                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n                                </h4>\n                                <div class=\"ShortDescription\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isMainHero : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </div>\n                            </div>\n";
  stack1 = ((helpers.ifStock || (depth0 && depth0.ifStock) || helperMissing).call(depth0, (depth0 != null ? depth0.stockCount : depth0), {"name":"ifStock","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\n                    </div>\n                </div>\n                <div class=\"ProductLink\">\n                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\"><img border=\"0\" src=\"/dd-cinemagic/img/transparent.png\"></a>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});

},{"116":116}],47:[function(require,module,exports){
'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('ifStock', function(conditional, options) {
    if (conditional > 0) {
        return options.fn(this);
    }
    return options.inverse(this);
});

},{"116":116}],45:[function(require,module,exports){
'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('heroClass', function(isMainHero) {
    if (isMainHero) {
        return 'hero1';
    }
    return 'hero2';
});

},{"116":116}],43:[function(require,module,exports){
'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('formatStock', function(stock) {
    if (stock > 10) {
        return '+10';
    }
    return stock;
});

},{"116":116}]},{},[26])

