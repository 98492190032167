// hbsfy compiled Handlebars template
var HandlebarsCompiler = require(116);
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                                        "
    + escapeExpression(((helper = (helper = helpers.shortDescription || (depth0 != null ? depth0.shortDescription : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"shortDescription","hash":{},"data":data}) : helper)))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                            <div class=\"StockCount\">\n                                <div class=\"StockCountText\">\n                                    <span class=\"StockVal\">"
    + escapeExpression(((helpers.formatStock || (depth0 && depth0.formatStock) || helperMissing).call(depth0, (depth0 != null ? depth0.stockCount : depth0), {"name":"formatStock","hash":{},"data":data})))
    + "</span> p&aring; lager\n                                </div>\n                                <div class=\"DeliveryTime\">\n                                    <div class=\"DeliveryTimeText js-deliveryTime\">\n                                        <span class=\"Stockmessage_Productlist\">1-2 dage</span> <span class=\"greyText\">leveringstid</span>\n                                    </div>\n                                </div>\n                            </div>\n";
},"5":function(depth0,helpers,partials,data) {
  return "                            <div class=\"StockCount\">\n                                <div class=\"StockCountText\">\n                                    P&aring; fjernlager\n                                </div>\n                                <div class=\"DeliveryTime\">\n                                    <div class=\"DeliveryTimeText js-deliveryTime\">\n                                        <span class=\"Stockmessage_Productlist\">3 dage</span> <span class=\"greyText\">leveringstid</span>\n                                    </div>\n                                </div>\n                            </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"hero "
    + escapeExpression(((helpers.heroClass || (depth0 && depth0.heroClass) || helperMissing).call(depth0, (depth0 != null ? depth0.isMainHero : depth0), {"name":"heroClass","hash":{},"data":data})))
    + "\">\n    <form style=\"margin:0px;\" method=\"post\" action=\"/shop/basket.asp\" name=\"myform"
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "\" onsubmit=\"return BuyProduct(this,'1','0','False');\">\n        <input type=\"hidden\" name=\"VerticalScrollPos\" value=\"\"><input type=\"hidden\" name=\"BuyReturnUrl\" value=\"/shop/frontpage.html\"><input type=\"hidden\" name=\"Add\" value=\"1\"><input type=\"hidden\" name=\"ProductID\" value=\""
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"small-6 medium-3 large-3 column product\">\n            <div class=\"Product List row\">\n                <div class=\"small-12 column Image\">\n                    <div>\n                        <a class=\"Product_ImageLink_FrontPage Product_ImageLink_Frontpage\" href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\"><img border=\"0\" src=\""
    + escapeExpression(((helper = (helper = helpers.pictureLink || (depth0 != null ? depth0.pictureLink : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"pictureLink","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\" id=\"321596\"></a>\n                        <div class=\"New\"></div>\n                        <div class=\"Offer\"></div>\n                        <div class=\"Overlay\"></div>\n                    </div>\n                </div>\n                <div class=\"small-12 column PriceBox\">\n                    <div class=\"row\">\n                        <div class=\"column small-5 Price\">\n                            <span class=\"js-productPrice hide\">\n                                "
    + escapeExpression(((helper = (helper = helpers.priceIncVAT || (depth0 != null ? depth0.priceIncVAT : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"priceIncVAT","hash":{},"data":data}) : helper)))
    + ",-\n                            </span>\n                            <span class=\"js-specialOfferPrice hide\"></span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"ProdInfo small-12 column\">\n                    <div class=\"ListProdInfo\">\n                        <div class=\"row\">\n                            <div class=\"small-12 column ProdInfoText\">\n                                <div class=\"ProductNumber hide\">\n                                    "
    + escapeExpression(((helper = (helper = helpers.number || (depth0 != null ? depth0.number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"number","hash":{},"data":data}) : helper)))
    + "\n                                </div>\n                                <h4>\n                                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n                                </h4>\n                                <div class=\"ShortDescription\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isMainHero : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                                </div>\n                            </div>\n";
  stack1 = ((helpers.ifStock || (depth0 && depth0.ifStock) || helperMissing).call(depth0, (depth0 != null ? depth0.stockCount : depth0), {"name":"ifStock","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                        </div>\n                    </div>\n                </div>\n                <div class=\"ProductLink\">\n                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"url","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\"><img border=\"0\" src=\"/dd-cinemagic/img/transparent.png\"></a>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"useData":true});
