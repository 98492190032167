'use strict';

var Handlebars = require(116);

Handlebars.registerHelper('formatStock', function(stock) {
    if (stock > 10) {
        return '+10';
    }
    return stock;
});
