'use strict';

var R = require(29);

var hero = require(33);
var productTile = require(21);
var headerMessage = require(15);

R(R.EVENTS.LOAD, R.SECTIONS.FRONTPAGE, function() {
    productTile.update();
    if (window.CineMagicConfig && window.CineMagicConfig.hero) {
        hero();
    }
    headerMessage.showMessage();
    R.fire(R.EVENTS.POST_LOAD);
});
